import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'

import useAlertNotification from '@/services/notification/useAlert'

export default function useDocuments() {
  const { errorToast, successToast } = useAlertNotification()
  const document = ref([])
  const loader = ref(false)
  const documentProcess = ref(false)
  const documentSuccess = ref(false)
  const documents = ref([])
  const errors = ref('')

  // Liste des documents
  const getDocuments = async () => {
    loader.value = true
    await axiosClient.get('/documents').then(response => {
      if (response.data.success === true) {
        loader.value = false
        documents.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir une document
  const getDocument = async slug => {
    const response = await axiosClient.get(`/documents/${slug}`)
    document.value = response.data.data
  }
  // Ajouter une document
  const createDocument = async data => {
    errors.value = ''
    documentProcess.value = true
    await axiosClient.post('/documents', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          documentSuccess.value = true
          documentProcess.value = false
          getDocuments()
        }
      })
      .catch(error => {
        documentProcess.value = false
        documentSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier une document
  const updateDocument = async data => {
    errors.value = ''
    documentProcess.value = true
    await axiosClient.patch(`/documents/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          documentSuccess.value = true
          documentProcess.value = false
          getDocuments()
        }
      })
      .catch(error => {
        documentProcess.value = false
        documentSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    document,
    documents,
    getDocuments,
    documentProcess,
    createDocument,
    updateDocument,
    getDocument,
    loader,
    documentSuccess,
  }
}
